import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";
import Grid from "@mui/material/Grid";

import AppStoreSVG from "../svg/appStoreButton.svg";

import * as styles from "./index.module.css";

// markup
const IndexPage = ({ data }) => {
  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nuff | A curated list of the best eating in East London</title>
      </Helmet>
      <Grid
        container
        columnSpacing={{ xs: 0, sm: "20px" }}
        margin="0 auto"
        className={styles.container}
        p={3}
      >
        <Grid item xs={12} sm={5} alignItems="center" order={{ xs: 2, sm: 1 }}>
          <Grid
            container
            columns={6}
            justifyContent="center"
            alignItems="center"
            className={styles.imageContainer}
          >
            <Grid
              item
              xs={6}
              sm={5}
              md={4}
              lg={3}
              className={styles.screenshotContainer}
            >
              <StaticImage
                className={styles.screenshotBG}
                placeholder="none"
                src="../images/slideshow-background.png"
                alt="A Screenshot of the Nuff app home Page"
              />
              <Carousel
                className={styles.carousel}
                indicators={false}
                navButtonsAlwaysInvisible={true}
                animation="slide"
                sx={{ overflowY: "visible", overflowX: "clip" }}
                swipe={false}
              >
                <StaticImage
                  className={styles.screenshot}
                  placeholder="none"
                  src="../images/screenshot-1.png"
                  alt="A Screenshot of the Nuff app home Page"
                />
                <StaticImage
                  className={styles.screenshot}
                  placeholder="none"
                  src="../images/screenshot-2.png"
                  alt="A Screenshot of the Nuff app home Page"
                />
                <StaticImage
                  className={styles.screenshot}
                  placeholder="none"
                  src="../images/screenshot-3.png"
                  alt="A Screenshot of the Nuff app home Page"
                />
              </Carousel>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          alignItems="center"
          order={{ xs: 1, sm: 2 }}
        >
          <Grid
            container
            columns={6}
            justifyContent="center"
            alignItems="center"
            className={styles.imageContainer}
          >
            <div className={styles.content}>
              <span className={styles.header}>
                <h1>Nuff</h1>
              </span>
              <h2>
                A tried and tested app for eating and drinking out in East
                London.
              </h2>
              <p>
                Founded by three mates with healthy appetites who’ve racked up
                too many dinner bills. For locals, and visitors alike. Buzzy
                restaurants. Unpretentious wine bars. Family-run institutions.
                Proper boozers. Decent brunch spots. Good coffee. A
                cherry-picked list of places to eat and drink, ordered by
                distance from your current location, accessible in the palm of
                your hand. An ever evolving list, rated by us. We'll even show
                you the receipts.
              </p>
              <p>
                Hit the download button. Eat well. Don’t forget to tell your
                friends.
              </p>
              <div className={styles.logos}>
                <a
                  className={styles.appleLogo}
                  href="https://apps.apple.com/gb/app/nuff/id1535231249"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AppStoreSVG />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.nuffapp.nuff&hl=en&gl=UK&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={styles.androidLogo}
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IndexPage;
